import 'jquery';
import 'owl.carousel';

let moreRecipes = document.querySelector('[data-more-recipes]');
let $slider = $('[data-recipes-slider]');
let initRecipesSlider = () => {
};
if ($slider) {
    initRecipesSlider = () => {
        let carousel = $slider.owlCarousel({
            items: 1,
            loop: true,
            nav: true,
            dots: false,
            navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
            smartSpeed: 450,
        });

        window.onload = () => (dispatchEvent(new Event('resize')))
    };

    initRecipesSlider();

    let moreRecipes = document.querySelector('[data-more-recipes]');
    if (moreRecipes) {
        moreRecipes.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            return false;
        });
    }
}

/**
 Recipes list page
 */

const page = document.querySelector('[data-recipes-list-page]');

if (page) {
    const filterButtons = page.querySelectorAll('[data-filter-category]');

    filterButtons.forEach(button => {
        button.addEventListener('click', e => {
            e.preventDefault();

            fetch(button.href)
                .then(body => body.text())
                .then(result => {
                    const parser = new DOMParser();
                    const newContent = parser.parseFromString(result, 'text/html').querySelector('[data-recipes-block]');
                    const oldContent = page.querySelector('[data-recipes-block]');
                    oldContent.outerHTML = newContent.outerHTML;
                    filterButtons.forEach(b => b.classList.remove('active'));
                    button.classList.add('active');
                    initRecipesSlider();
                    moreRecipesButton()
                })
                .catch(()=>{})
        })
    })
    /**
     * Выбрать категорию
     */
    const chooseCategoryButton = page.querySelector('[data-catalog-categories-filter-choose]');
    const filterCategories = page.querySelector('[data-filter-categories]');
    let clicked = false;

    const filterActive = () => {
        chooseCategoryButton.classList.add('active');
        filterCategories.classList.add('active');
    }

    const filterNotActive = () => {
        chooseCategoryButton.classList.remove('active');
        filterCategories.classList.remove('active');
    }

    chooseCategoryButton.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!clicked) {
            filterActive();
            clicked = true;
        } else {
            filterNotActive();
            clicked = false;
        }

        return false;
    });


}

/**
 Recipes view page
 */

const ingredients = document.querySelector('[data-ingredients-slider]');
if (ingredients) {
    $(ingredients).owlCarousel({
        items: 4,
        loop: true,
        nav: true,
        dots: false,
        navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
        smartSpeed: 450,
        responsive: {
            0: {
                items: 2,
            },
            768: {
                items: 4
            }
        }
    });
    window.onload = () => (dispatchEvent(new Event('resize')))
}


/**
 Еще рецепты кнопка
 */
function moreRecipesButton() {
    const block = document.querySelector('[data-recipes-block]');
    if (block) {
        const recipesMoreButton = block.querySelector('[data-more-recipes]');
        if (recipesMoreButton) {
            const recipes = block.querySelectorAll('.recipe-item');
            let counter = 0;

            recipesMoreButton.addEventListener('click', e => {
                e.preventDefault();
                for (let i = 0; i < recipes.length; i++) {
                    if (!recipes[i + 1]) {
                        recipesMoreButton.classList.remove('active')
                        break
                    }
                    let el = recipes[i];
                    if (el.classList.contains('hidden')) {
                        if (counter <= 3) {
                            counter++
                            el.classList.remove('hidden');
                        }
                        if (counter === 3) {
                            counter = 0
                            break
                        }
                    }

                }
            });
        }
    }
}

moreRecipesButton()