const page = document.querySelector('[data-promo-page]');
import './vendor/owlcarousel2-filter';

if (page) {
    const shopsSlider = page.querySelector('[data-shops-slider]');
    const promosSlider = page.querySelector('[data-promos-slider]');
    const state = {
        prevShopId: -1,
        shopId: 0,
        shopName: '',
        prevAddressId: 0,
        addressId: 0,
        toggleText: '',
        productsSlider: {}
    };
    const fetchProductsUrl = '/promo/products';

    function init() {
        const myMap = new ymaps.Map('map', {
            center: [55.7, 37.5],
            zoom: 13,
            controls: ['zoomControl']
        });
        // Создаем коллекцию.
        let myCollection = new ymaps.GeoObjectCollection();
        // Создаем массив с данными.
        const myPoints = window.mapPoints;

        // Заполняем коллекцию данными.
        function fill(mapPoints) {
            myCollection.removeAll();
            mapPoints.forEach((point) => {
                let placemark = new ymaps.Placemark(
                    point.coords, {
                        balloonContentBody: point.text
                    }, {
                        iconLayout: 'default#image',
                        iconImageHref: '/static/images/promo__balloon.svg',
                        // Размеры метки.
                        iconImageSize: [25, 36],
                        iconImageOffset: [0, 0],
                    }
                );
                myCollection.add(placemark);
            });
        }

        fill(myPoints);
        myMap.geoObjects.add(myCollection);

        const location = ymaps.geolocation.get({
            autoReverseGeocode: false
        });

        // Асинхронная обработка ответа.
        location.then(
            result => myMap.setCenter(result.geoObjects.position),
            err => console.log(err)
        );
        myMap.behaviors.disable('scrollZoom');
        myMap.behaviors.disable('ruler');
        myMap.behaviors.disable('routeEditor');

        /**
         * Поиск
         */
        const search = document.querySelector('#search');
        let timeout;
        document.addEventListener('keyup', e => {
            if (e.target === search) {
                clearTimeout(timeout);
                let newPoints = myPoints.filter(value => {
                    return value.text.toUpperCase().indexOf(e.target.value.toUpperCase()) !== -1;
                });
                timeout = setTimeout(() => {
                    fill(newPoints);
                }, 500);
            }
        });

        /**
         * Слайдеры
         */
        $(shopsSlider).owlCarousel({
            items: 6,
            nav: true,
            navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
            responsive: {
                0: {
                    items: 1,
                },
                768: {
                    items: 4
                },
                1280: {},
                1401: {}
            }
        });
        const promos = $(promosSlider).owlCarousel({
            items: 3,
            nav: true,
            margin: 10,
            dots: true,
            navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                768: {
                    items: 2
                },
                1280: {},
                1401: {}
            }
        });

        $(shopsSlider).on('click', '[data-shop-id]', function (e) {
            $(shopsSlider).find('[data-shop-id]').each((index, element) => {
                element.classList.remove('active')
            });

            this.classList.add('active');
            let id = this.getAttribute('data-shop-id');
            let name = this.getAttribute('data-name');
            makeActive(id, name);
        });

        $(promosSlider).on('click', '[data-shop-id]', function (e) {
            let id = Number(this.getAttribute('data-shop-id'));
            let newPoints = myPoints.filter(value => {
                return value.shopId == id
            });

            state.prevShopId = state.shopId;
            state.shopId = id;

            fill(newPoints);
        });

        /**
         *  Мобила клик на магазин
         */
        const $currentShop = $('[data-shop-current]');
        const $shopList = $('[data-shop-list]');
        $currentShop.on('click', function (e) {
            $currentShop.toggleClass('active');
            $shopList.toggleClass('active');

        });
        $shopList.find('[data-shop-id]').on('click', function (e) {
            makeActive(this.getAttribute('data-shop-id'), this.getAttribute('data-name'));
            $shopList.removeClass('active');
        });

        function makeActive(id, name) {
            state.prevShopId = state.shopId;
            if (id == "*") {
                promos.owlcarousel2_filter(`[data-shop-id]`);
                state.shopName = '';
                state.shopId = '0';
                changeShopName();
                if (state.prevShopId !== state.shopId) {
                    fetchProducts();
                }
                fill(window.mapPoints);
                return;
            }

            state.shopName = name;
            changeShopName();
            state.shopId = id;
            promos.owlcarousel2_filter(`[data-shop-id=${id}]`);
            if (state.prevShopId !== state.shopId) {
                fetchProducts();
            }

            let newPoints = myPoints.filter(value => {
                return value.shopId == id
            });

            fill(newPoints);
        }

        window.dispatchEvent(new Event('resize'));

    }


    const changeShopName = () => {
        const wrapper = document.querySelector("[data-shop-name]");
        if (wrapper) {
            if (state.shopName) {
                wrapper.innerText = `«${state.shopName}»`;
                return;
            }
            wrapper.innerText = '';
        }

    };
    ymaps.ready(init);

    let $showMoreButton = $('[data-show-more-button]');
    let $products = $('[data-products]');
    let $productsText = $products.find('[data-products-text]');
    let $productsItems = $products.find('[data-products-items]');
    state.toggleText = $productsText.html();

    /**
     * Показать еще
     */
    $showMoreButton.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        let $wrappers = document.querySelectorAll('[data-products-items] .wrapper');
        console.log($wrappers);
        for (let i = 0; i < $wrappers.length; i++) {
            let current = $wrappers[i];
            if (i == $wrappers.length - 1) {
                this.classList.remove('active')
            }
            if (!current.classList.contains('active')) {
                current.classList.add('active');
                break;
            }
        }
    });

    /**
     * fetch products by shopId
     * @param shopId
     */
    function fetchProducts(shopId = state.shopId) {
        fetch(`${fetchProductsUrl}?brand=${shopId}`)
            .then(res => res.json())
            .then(body => {
                if (body.error === true) {
                    return;
                }
                if (body.products) {
                    // get items from json and make string with elements
                    const products = Object.values(body.products);
                    if (state.productsSlider !== products) {
                        const elements = products.map(item => {
                            return (`
                                <a target="_blank" style="display: block;" class="product" href="${item.url}"> 
                                    <span class="image-wrapper" title='${item.name}'>
                                        <img src="${item.image}" alt='${item.name}'>
                                    </span>
                                    <span class="name">${item.name}</span>
                                </a>
                            `);
                        });
                        $productsItems.html("");
                        const pages = chunk(elements, 6);
                        pages.forEach((page, index) => {
                            const content = page.join("");
                            $productsItems.html(`${$productsItems.html()}<div class='wrapper${index === 0 ? ' active' : ''}'> ${content} </div>`);
                            index > 1 ? $showMoreButton.addClass('active') : "";
                        });
                        state.productsSlider = products;
                        $productsItems.owlCarousel('destroy');
                        if (window.innerWidth >= 1280) {
                            $productsItems.owlCarousel({
                                items: 1,
                                nav: true,
                                navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
                            });
                        }
                        $productsItems = $('[data-products-items]');
                    }
                }
            })
    }

    /**
     * Клик "Посмотреть всю продукцию в магазине"
     */
    $productsText.on('click', function (e) {
        e.preventDefault();

        $productsText.toggleClass('active');
        $productsItems.toggleClass('active');

        if ($productsText.hasClass('active')) {
            $productsText.html(`<span class="close-icon"></span><span class="text">Закрыть</span>`);
            $showMoreButton.addClass('show');
        } else {
            $showMoreButton.removeClass('show');
            $productsText.html(state.toggleText);
            changeShopName();
        }

        return false;
    });

    function chunk(arr, chunkSize) {
        var R = [];
        for (var i = 0, len = arr.length; i < len; i += chunkSize)
            R.push(arr.slice(i, i + chunkSize));
        return R;
    }

    fetchProducts();
}


/**
 Modal goods
 */
document.addEventListener('modal-after-open', function () {
    let hiddenRows, promoGoods, button;
    hiddenRows = null;
    promoGoods = null;
    button = null;
    promoGoods = document.querySelector('[data-promo-goods]');
    button = promoGoods ? promoGoods.querySelector('[data-show-more]') : null;
    if (button) {
        button.addEventListener('click', function (e) {
            e.preventDefault();
            let counter = 0;
            hiddenRows = promoGoods.querySelectorAll('.promo__goods_row.hidden');
            if (hiddenRows.length > 0) {
                for (let i of hiddenRows) {
                    counter++;
                    i.classList.remove('hidden');
                    hideButton();
                    if (counter === 3) {
                        break;
                    }
                }
            }
        })
    }

    function hideButton() {
        let hiddenRows = promoGoods.querySelectorAll('.promo__goods_row.hidden');
        if (hiddenRows.length === 0) {
            button.classList.add('hide')
        }
    }
})