"use strict";

window.recipeVideos = [];

function videosInit() {
    let videos = document.getElementsByClassName("youtube");

    let nb_videos = videos.length;
    for (let i = 0; i < nb_videos; i++) {
        const $video = videos[i]
        // Based on the YouTube ID, we can easily find the thumbnail image
        let posterUrl = $video.getAttribute('data-poster') ? $video.getAttribute('data-poster') : 'http://i.ytimg.com/vi/' + $video.id + '/sddefault.jpg'
        $video.style.backgroundImage = `url('${posterUrl}')`
        if (!$video.querySelector('.play-button')) {
            // Overlay the Play icon to make it look like a video player
            let play = document.createElement("div");
            play.classList.add('play-button');
            $video.appendChild(play);
        }
        $video.onclick = function () {
            const onPlayerReady = (event) => {
                event.target.playVideo();
            };
            recipeVideos[i] = new YT.Player($video, {
                height: '390',
                width: '640',
                videoId: $video.id,
                events: {
                    'onReady': onPlayerReady
                }
            });

        }
    }
}

document.addEventListener('DOMContentLoaded', e => {
    setTimeout(videosInit, 10)
})

document.addEventListener('video-carousel-translated', e => {
    videosInit()
    recipeVideos.forEach(el => {
        if (typeof el.stopVideo === 'function') el.stopVideo();
    })
})