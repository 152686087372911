const state = {
    menuOpened: false,
    subMenuOpened: false
};
const header = document.querySelector('header');
const hoverItems = document.querySelectorAll('.menu-item.has-children');
const hoverChildren = header.querySelectorAll('[data-category-id].has-children');

const openMenu = element => {
    state.menuOpened = true;
    element.classList.add('active')
};

const closeMenu = element => {
    if (state.subMenuOpened === false) {
        state.menuOpened = false;
        element.classList.remove('active')
    }
};

const openMenuChildren = element => {
    state.subMenuOpened = true;
    document.querySelector(`[data-subchildren="${element.getAttribute('data-category-id')}"]`).classList.add('active')
};

const closeMenuChildren = element => {
    state.subMenuOpened = false;
    document.querySelector(`[data-subchildren="${element.getAttribute('data-category-id')}"]`).classList.remove('active')
};


const showMenu = (element, functionOpen = openMenu, functionClose = closeMenu) => {
    let timeout;

    element.addEventListener('mouseover', function (e) {
        if (timeout) {
            clearTimeout(timeout);
        }
        functionOpen(element);
    });

    element.addEventListener('mouseleave', function (e) {
        timeout = setTimeout(() => {
            functionClose(element)
        }, 500);
    });
};

hoverItems.forEach(element => {
    showMenu(element)
});

hoverChildren.forEach(element => {
    showMenu(element, openMenuChildren, closeMenuChildren)
});