import 'owl.carousel';

document.addEventListener('DOMContentLoaded', () => {
    const page = document.querySelector('[data-index-page]');
    if (page) {
        let $slider = $('[data-index-slider]');
        let $newsSlider = $('[data-index-news-slider]');
        if (!!$slider.length) {
            $slider.on('initialized.owl.carousel', () => {
                $(document).trigger('resize');
            });
            $slider.owlCarousel({
                items: 1,
                dots: 1,
                loop: true,
                smartSpeed: 600,
                responsive: {
                    0: {
                        arrows: 1,
                        dots: 0,
                    },
                    768: {
                        arrows: 0,
                        dots: 1
                    },
                    9999: {
                        arrows: 0,
                        dots: 1
                    }
                },
                responsiveRefreshRate: 600
            })
        }

        if (!!$newsSlider.length) {
            $newsSlider.on('initialized.owl.carousel', () => {
                $(document).trigger('resize');
            });
            $newsSlider.owlCarousel({
                items: 2,
                dots: false,
                nav: true,
                loop: true,
                smartSpeed: 600,
                navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
                responsive: {
                    0: {
                        items: 1
                    },
                    768: {
                        items: 1
                    },
                    1280: {
                        items: 2
                    },
                    9999: {
                        arrows: 0,
                        dots: 1
                    }
                },
                responsiveRefreshRate: 600,
            })
        }
        window.load = () => window.dispatchEvent(new Event('resize'));
        setTimeout(() => window.dispatchEvent(new Event('resize')), 5000)
    }
});
