import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";

const zenscroll = require('zenscroll')

new LiveEvent('click', '[data-modal]', function openModal(e) {
    e.preventDefault();
    let link = this;
    let modal = new Modal(this, {
        onAfterOpen: function () {
            document.dispatchEvent(new Event('modal-after-open'))
        },
        onFormSuccess: function () {
            if (link.dataset.goal) {
                window.goal(link.dataset.goal);
            }
        },
        closeOnSuccess: false
    });
    return false;
});


document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('[data-smooth-scroll]').forEach(link => {
        link.addEventListener('click', e => {

            const el = document.querySelector(link.hash);
            if (el) {
                e.preventDefault()
                zenscroll.to(el)
            }
        })
    })
})