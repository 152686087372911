function filterSmall() {
    let chooseCategoryButton = document.querySelector('[data-catalog-categories-filter-choose]');
    let categoriesFilter = document.querySelector('[data-catalog-categories-filter]');
    let clicked = false;
    let subCatClicked = false;
    let subCategoryLinks = categoriesFilter.querySelectorAll('[data-subcategory]');

    /**
     * Клик на подкатегорию
     */
    subCategoryLinks.forEach(element => {
        element.addEventListener('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (subCatClicked !== this.dataset.subcategory) {
                let subCategoryId = this.dataset.subcategory;
                showSubcategory(subCategoryId);
                subCatClicked = this.dataset.subcategory;
                filterNotActive();
            }
            return false;
        })
    });

    function showSubcategory(subcategoryId) {
        let categories = document.querySelector('[data-categories]');
        let subcategories = categories.querySelectorAll('[data-subcategory]');
        subcategories.forEach(subcategory => {
            subcategory.classList.remove('small-title-hidden');
            subcategory.classList.add('small-hidden');
            if (subcategory.dataset.subcategory === subcategoryId) {
                subcategory.classList.add('small-title-hidden');
                subcategory.classList.remove('small-hidden');
                document.querySelector('[data-catalog-subcategory-title]').innerText = subcategory.querySelector('.title').innerText;
            }
        })
    }

    /**
     * Выбрать категорию
     */
    chooseCategoryButton.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!clicked) {
            filterActive();
            clicked = true;
        } else {
            filterNotActive();
            clicked = false;
        }

        return false;
    });

    function filterActive() {
        chooseCategoryButton.classList.add('active');
        categoriesFilter.classList.add('active');
    }

    function filterNotActive() {
        chooseCategoryButton.classList.remove('active');
        categoriesFilter.classList.remove('active');
    }
}

let catalogPageElement = document.querySelector('div.catalog-page');
if (catalogPageElement) {

    /**
     * Ajax filter
     */
    function catalogFilterAjax() {
        let wrapper = document.querySelector('[data-catalog-categories-filter]');
        let links = wrapper.querySelectorAll('[data-category]');
        links.forEach(function (element) {
            element.addEventListener('click', function (event) {
                event.preventDefault();
                event.stopPropagation();

                let linkHref = element.href;
                let page = getPage(`${linkHref}`);
                document.body.style.cursor = 'wait';
                element.style.pointerEvents = 'none';
                page
                    .then(res => {
                        return res.text()
                    })
                    .then(page => {
                        let parser = new DOMParser();
                        let doc = parser.parseFromString(page, "text/html");
                        document.querySelector('[data-catalog-page]').innerHTML = outerHTML(doc.querySelector('[data-page-wrapper]'));
                        init();
                        window.history.pushState("", null, element.href);
                        catalogPageElement.classList.remove('category-id-17');
                        if (element.pathname === '/catalog/tomatnaya-gruppa') {
                            catalogPageElement.classList.add('category-id-17');
                        }
                    })
                    .catch(err => console.info(err))
                    .finally(() => {
                        document.body.style.cursor = '';
                        element.style.pointerEvents = '';
                    });

                return false;
            })
        })
    }

    function init() {
        catalogFilterAjax();
        filterSmall();
    }

    init();

    /**
     * Фильтр для телефона
     */


    filterSmall();

    /**
     * Fetch page
     * @param url
     * @returns promise
     */
    function getPage(url) {
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'text/html'
            },
        };
        return fetch(url, options);
    }

    /**
     * Get innerText of HTML Node
     * @param node
     * @returns string
     */
    function outerHTML(node) {
        return node.outerHTML || new XMLSerializer().serializeToString(node);
    }
}