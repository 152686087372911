const page = document.querySelector('[data-opt-page]');

if (page) {
    let $slider = $('[data-our-products-slider]');
    let carousel = $slider.owlCarousel({
        items: 4,
        loop: true,
        // rewind    : true,
        nav: true,
        dots: false,
        margin: 40,
        // center: true,
        navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1280: {
                items: 4
            },
            1401: {
                items: 4
            }
        }
    });
    document.addEventListener('DOMContentLoaded', e => {
        window.dispatchEvent(new Event('resize'));
    })
}