if (document.querySelector('[data-catalog-view-page]')) {
    /**
     * Другие продукты карусель
     */
    function otherProductsCarousel() {
        let $carouselOtherProducts = $('[data-catalog-other-products]');
        let carousel = $carouselOtherProducts.owlCarousel({
            items: 4,
            margin: 40,
            loop: true,
            nav: true,
            dots: false,
            navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1280: {
                    items: 3
                },
                1401: {}
            }
        });

        window.onload = () => (window.dispatchEvent(new Event('resize')))
    }

    otherProductsCarousel();

    /**
     * Товар карусель
     */
    const $slider = $('[data-slider]');

    if ($slider.length) {
        $slider.owlCarousel({
            items: 1,
            loop: true,
            nav: true,
            dots: false,
            navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
        })
    }
    window.addEventListener('load', e => {
        window.dispatchEvent(new Event('resize'));
    })
}