import LazyLoad from "vanilla-lazyload";
require('mutationobserver-shim')

const lazyLoad = new LazyLoad();
const observer = new MutationObserver(function (mutations) {
    lazyLoad.update();
});
const observerConfig = {
    attributes: true,
    childList: true,
    characterData: true
};
const targetNode = document.body;
observer.observe(targetNode, observerConfig);


export default {lazyLoad};