(function ($) {

    "use strict";

    /**
     * Описание объекта
     */
    var cart = function () {
        return cart.init.apply(this, arguments);
    };

    /**
     * Расширение объекта
     */
    $.extend(cart, {
        /**
         * Настройки по умолчанию
         */
        options: {
            routes: {
                index: '/cart',
                add: '/cart/add',
                remove: '/cart/remove',
                inc: '/cart/inc',
                dec: '/cart/dec',
                quantity: '/cart/quantity'
            },
        },
        current_button: {
            object: '',
            text: '',
        },
        /**
         * Инициализация
         * @param options
         */
        init: function (options) {
            this.options = $.extend(this.options, options);

            this.bind();

            return this;
        },
        /**
         * "Навешиваем" события
         */
        bind: function () {
            var me = this;

            $(document).on('click', '[data-to-cart]', function(e) {
                e.preventDefault();
                var $button = $(this);
                const $quantity_input = $('[data-to-cart-value="'+$button.data('id')+'"]');
                const quantity = $quantity_input.length ? $quantity_input.val() : false;
                me.current_button.object = $button;
                me.current_button.text = $button.html();
                $button.html('<i class="fas fa-circle-notch fa-spin"></i>');
                me.add($button.data('id'), quantity);
                return false;
            });

            $(document).on('click', '[data-cart-dec]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.current_button.object = $button;
                me.current_button.text = $button.html();
                $button.html('<i class="fas fa-circle-notch fa-spin"></i>');
                me.dec($button.data('cart-dec'), $button.data('step'));
                return false;
            });

            $(document).on('click', '[data-cart-inc]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.current_button.object = $button;
                me.current_button.text = $button.html();
                $button.html('<i class="fas fa-circle-notch fa-spin"></i>');
                me.inc($button.data('cart-inc'), $button.data('step'));
                return false;
            });

            $(document).on('click', '[data-cart-remove]', function(e) {
                e.preventDefault();
                var $button = $(this);
                me.current_button.object = $button;
                me.current_button.text = $button.html();
                $button.html('<i class="fas fa-circle-notch fa-spin"></i>');
                me.remove($button.data('cart-remove'));
                return false;
            });
        },
        add: function (id, quantity) {
            this.request(this.options.routes.add, {
                id: id,
                quantity: quantity
            });
        },
        dec: function (position, quantity) {
            this.request(this.options.routes.dec, {
                position: position,
                quantity:quantity
            });
        },
        inc: function (position, quantity) {
            this.request(this.options.routes.inc, {
                position: position,
                quantity: quantity
            });
        },
        remove: function (position) {
            this.request(this.options.routes.remove, {
                position: position
            });
        },
        request: function (route, request) {
            var me = this;
            $.ajax({
                url: route,
                dataType: 'json',
                type: 'post',
                data: request,
                success: function (data) {
                    if (data.status && data.message) {
                        me.message(status, data.message)
                        me.afterRequest(false);
                    }
                    if (data.status == "success") {
                        me.update();
                        me.afterRequest(true);
                    }
                }
            });
        },
        afterRequest: function (result) {
            if(result){
                if(this.current_button.object){
                    this.current_button.object.html(this.current_button.text);
                }

                $.ajax({
                    url: this.options.routes.quantity,
                    dataType: 'json',
                    type: 'get',
                    success: function (data) {
                        $('[data-cart-quantity]').text(data);
                    }
                });

            }
        },
        /**
         * @param status "success"|"error"
         * @param message
         */
        message: function (status, message) {
            $('#success_cart').addClass('scale-up-ver-bottom');
            $('#success_cart').css('display','block');
            $('#success_cart p').html(message);


            var myVar = setInterval(myTimer, 5000);

        },
        update: function () {
            var me = this;
            var $blocks = $('[data-cart-block]');
            if ($blocks.length > 0) {
                $blocks.addClass('loading');
                $.ajax({
                    success: function (page) {
                        var $page = $(page);
                        me.updateBlocks($page);
                    }
                });
            }
        },
        updateBlocks: function ($page) {
            $page.find('[data-cart-block]').each(function () {
                var $newBlock = $(this);
                var name = $newBlock.data('cart-block');
                var $block = $('[data-cart-block="' + name + '"]');
                $block.removeClass('loading');
                $block.html($newBlock.html());
            });
        },
    });

    /**
     * Инициализация функции объекта для jQuery
     */
    return $.cart = function (options) {
        return cart.init(options);
    };

})($);

//$(function(){
//	$('body').cart();
//});

$.cart();

function myTimer() {
    $('#success_cart').css('display','none');
    $('#success_cart').removeClass('scale-up-ver-bottom');
}
