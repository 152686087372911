function initMobileMenu() {
    let menu = document.querySelector('[data-mobile-menu]'),
        open = document.querySelector('[data-mobile-menu-open]'),
        close = document.querySelector('[data-mobile-menu-close]'),
        lvl = document.getElementsByClassName('mobile-has-children'),
        closeBtn = document.getElementsByClassName('close-lvl'),
        scrollNone = document.querySelector('body');

    function openMenu() {
        scrollNone.classList.add('scroll-none');
        menu.classList.remove('closed');
        menu.classList.add('opened');

    }

    function closeMenu() {
        scrollNone.classList.remove('scroll-none');
        menu.classList.remove('opened');
        menu.classList.add('closed');
    }

    function openLvl(i) {
        i.parentElement.classList.remove('closed');
        i.parentElement.classList.add('opened');
    }

    function closeLvl(i) {
        i.parentElement.parentElement.classList.remove('opened');
        i.parentElement.parentElement.classList.add('closed');
    }

    function closeAllLvlMenu(i) {
        menu.parentElement.classList.remove('opened');
        i.parentElement.parentElement.classList.remove('opened');
        menu.parentElement.classList.add('closed');
    }

    for(let i of lvl) {
        i.addEventListener('click', function (e) {
            e.preventDefault();
            openLvl(i);
            return false;
        });
    };

    for(let i of closeBtn) {
        i.addEventListener('click', function (e) {
            e.preventDefault();
            closeLvl(i);
            return false;
        });

        close.addEventListener('click', function (e) {
            e.preventDefault();
            closeAllLvlMenu(i);
            return false;
        });

    };

    open.addEventListener('click', function (e) {
        e.preventDefault();
        openMenu();
        return false;
    });

    close.addEventListener('click', function (e) {
        e.preventDefault();
        closeMenu();
        return false;
    });
}

initMobileMenu();

export {initMobileMenu};