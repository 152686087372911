import 'jquery';
import Plyr from 'plyr';

import 'owl.carousel';

// Plyr video player
const playBtn = `<svg width="120" height="120" viewBox="0 0 120 120" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><circle fill="#E6040C" opacity=".6703683" cx="60" cy="60" r="60"/><path stroke="#FFF" stroke-width="9" fill="#FFF" stroke-linecap="round" stroke-linejoin="round" d="M74.5 61.1691176L51 75.3382353V47z"/></g></svg>`

function initPlayer(video) {
    let player = new Plyr(video, {
        urls: {
            youtube: {
                sdk: '',
                api: ''
            },
            googleIMA: {
                sdk: ''
            }
        }
    });
    player.poster = video.dataset.poster ? video.dataset.poster : "";
    player.on('ready', e => {
        document.querySelectorAll('.plyr__control--overlaid').forEach($el => {
            $el.innerHTML = playBtn
        });
    });
}

window.initPlyr = () => {
    document.querySelectorAll('.owl-item [data-plyr]').forEach((element, i) => {
        initPlayer(element)
    });

    // Just a clean up
    document.querySelectorAll('script').forEach(element => {
        !element.src.indexOf(".js") ? element.remove() : null;
    });
    return true;
};


// Carousel

let $videos = $('[data-video-slider]');

$videos.on('initialized.owl.carousel', function (e) {
    toggleCarouselClasses();
    window.initPlyr();
});
let carousel = $videos.owlCarousel({
    items: 3,
    loop: true,
    nav: true,
    dots: false,
    navText: ['<div class="carousel-prev"></div>', '<div class="carousel-next"></div>'],
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 1
        },
        1280: {
            items: 3
        },
        1401: {}
    }
});

function toggleCarouselClasses() {
    const activeElements = document.querySelectorAll('.owl-item.active');
    activeElements.forEach(($el, index) => {
        $el.classList.remove('no-mask');
        if (window.innerWidth >= 1280 && index === 1) {
            $el.classList.add('no-mask');
        }
        if (window.innerWidth <= 1279 && index === 0) {
            $el.classList.add('no-mask');
        }
    });

    return true;
}

carousel.on('translated.owl.carousel', function (e) {
    document.dispatchEvent(new Event('video-carousel-translated'))
    toggleCarouselClasses();
});
