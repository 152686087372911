require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
require('@fancyapps/fancybox/dist/jquery.fancybox.js');
require('inputmask/dist/jquery.inputmask.bundle.js');
require('whatwg-fetch');
require("../fonts/ForteVR/stylesheet.css");
require("../fonts/ProximaNova/stylesheet.css");
require('../scss/app.scss');

window.noZensmooth = true;

require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/main.js');
require('./common/pagination.js');
require('./common/respond.js');
require('./mobile-menu.js');
require('./video.js');
require('./recipes.js');
require('./parallax.js');
require('./catalog.js');
require('./catalog_view.js');
require('./menu.js');
require('./opt.js');
require('./promo.js');
require('./videos.js');
require('./about.js');
require('./partners.js');
require('./index.js');
require('./lazy.js');
require('../components/video');
require('./common/cart.js');

import "@fortawesome/fontawesome-free/js/all";

$('[data-fancybox]').fancybox({
    youtube: {
        showinfo: 0
    },
    vimeo: {
        color: 'f00'
    }
});

$(document).ready(function () {
    $('[data-ym-form]').submit(function () {
        if (typeof yaCounter55085530 != 'undefined') {
            var goal = $(this).data('ym-form');
            if (goal) {
                yaCounter55085530.reachGoal(goal);
            }
        }
    });
});