const page = document.querySelector('[data-about-page]');

if (page) {
    const partnersSlider = page.querySelector('[data-about-partners-slider]');
    const partnersSliderSlick = $(partnersSlider).owlCarousel({
        items: 4,
        loop: true,
        nav: true,
        dots: false,
        navText: ['<div class="carousel-prev black"></div>', '<div class="carousel-next black"></div>'],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 3
            },
            1280: {
                items: 4
            },
            1401: {
                items: 4
            }
        }
    })
    window.onload = () => {
        window.dispatchEvent(new Event('resize'))
    }
}
