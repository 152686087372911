/**
 * Videos page
 */
import 'jquery';
import Plyr from 'plyr';

const page = document.querySelector('[data-news-videos-list-page]');
const filterItemsWrapper = page ? page.querySelector('[data-filter-items]') : false;

if (filterItemsWrapper && page) {
    const filterItems = filterItemsWrapper.querySelectorAll('[data-filter-item]');
    const itemsWrapper = page.querySelector('[data-items]');
    const activeItems = () => itemsWrapper.querySelectorAll('[data-item-category].active');
    const items = () => state.currentCategory === 'all'
        ? itemsWrapper.querySelectorAll('[data-item-category]')
        : itemsWrapper.querySelectorAll(`[data-item-category="${state.currentCategory}"]`);

    const allItems = () => itemsWrapper.querySelectorAll('[data-item-category]');

    const state = {
        pageSize: 8,
        currentPage: 1,
        prevCategory: 'all',
        currentCategory: 'all'
    };
    filterItems.forEach(el => {
        el.addEventListener('click', e => {
            e.preventDefault();
            filterButtonToggle(el);
            showCategoryItems();
            toggleShowMoreButton();
            document.dispatchEvent(new Event('videosUpdated'));
            return false;
        })
    });

    function filterButtonToggle(element) {
        filterItems.forEach(el => {
            el.classList.remove('active')
        });
        state.prevCategory = state.currentCategory;
        state.currentCategory = element.getAttribute('data-filter-item');
        element.classList.add('active');
    }

    function showCategoryItems(categoryId = state.currentCategory) {
        let itemsArray = [...items()];
        itemsArray.length = 8;
        allItems().forEach(item => {
            item.classList.remove('active');
        });
        if (categoryId == 'all') {
            itemsArray.forEach(item => {
                item.classList.add('active');
            });
            return;
        }

        itemsArray.forEach(item => {
            if (item.getAttribute('data-item-category') == state.currentCategory) {
                item.classList.add('active');
            }
        })
    }

    function toggleShowMoreButton() {
        const showMoreButton = document.querySelector('[data-show-more]');
        if (showMoreButton) {
            showMoreButton.addEventListener('click', e => {
                e.preventDefault()
                showMore();
            });
            if (notActiveItems().length === 0) {
                showMoreButton.classList.add('hide');
                return;
            }
            showMoreButton.classList.remove('hide');
        }
    }

    function notActiveItems() {
        let active = [...activeItems()];
        let allItemsArr = [...allItems()];
        return allItemsArr.filter(value => active.indexOf(value) === -1);
    }

    function showMore() {
        let notActiveItemsArr
        if (state.currentCategory === 'all') {
            notActiveItemsArr = notActiveItems()
        } else {
            notActiveItemsArr = notActiveItems().filter(el => {
                return el.getAttribute('data-item-category') == state.currentCategory;
            });
        }
        notActiveItemsArr.length = state.pageSize;
        notActiveItemsArr.forEach(el => el.classList.add('active'));
        toggleShowMoreButton();
        document.dispatchEvent(new Event('videosUpdated'));
    }

    toggleShowMoreButton()
}